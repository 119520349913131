@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
@import "react-date-range/dist/styles.css"; // main css file
@import "react-date-range/dist/theme/default.css"; // theme css file

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Plus Jakarta Sans", sans-serif;
}

table,
div,
input,
p,
button,
span,
input::placeholder {
  font-family: "Plus Jakarta Sans", sans-serif;
}
