.container {
  width: 100%;
  @apply mx-auto;
  @apply px-5;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

.App {
  text-align: center;
}

.ant-carousel {
  width: 100% !important;
  height: 100% !important;
}

:where(.css-dev-only-do-not-override-ixblex).ant-picker
  .ant-picker-input
  > input {
  color: #ffffff;
}

.form-field_input_2 {
  padding: 12px 10px 12px 10px !important;
  outline-width: 0 !important;
  border: 1px solid #efefef;
  border-radius: 10px;
  width: 100%;
  transition: all 200ms ease-in-out;
  background: #ffffff;
  color: #01061f;
  font-weight: 600 !important;
  font-size: 14px;
}

.form-waiting {
  padding: 4px 10px 4px 4px !important;
  outline-width: 0 !important;
  border: none;
  border-radius: 18px 0px 0px 18px;
  outline: none !important;
  width: 100%;
  transition: all 200ms ease-in-out;
  background: #121d42;
  color: #ffffff;
  font-weight: 400 !important;
  font-size: 14px;
}

.form-field_input_2:hover,
.form-field_input_2:focus,
.form-field_input_2:active {
  border: 1px solid #fc622f;
  outline-width: 0 !important;
}

.form-waiting:hover,
.form-waiting:focus,
.form-waiting:active {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  outline-width: 0 !important;
  background: #121d42;
}

.form-field_input_2:disabled {
  background: #fafbff;
  font-size: 14px;
  color: #000000;
}

.form-field_input_2::placeholder,
.ant-picker-input input::placeholder {
  color: #898989 !important;
  font-size: 14px;
  font-weight: 400 !important;
}

.form-waiting::placeholder {
  color: #c8c8c8 !important;
  font-weight: 400 !important;
}

.ant-form-item-explain-error {
  font-size: 12px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fc622f !important;
  border-color: #fc622f !important;
}

.filter-modal {
  width: fit-content !important;
}

.transparent-bg {
  background: transparent !important;
}

.ant-select-selector {
  border: none !important;
  padding: 0px !important;
  height: 24px !important;
  outline: none;
}

ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-pagination-item-active {
  background: #f9f5ff !important;
  color: #7f56d9 !important;
  border: none !important;
}

.ant-pagination-item-active a {
  color: #7f56d9 !important;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper
  .ant-table-pagination.ant-pagination {
  display: flex;
  justify-content: center;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper
  .ant-table-thead
  > tr
  > th {
  background: #f2f2f2 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #657081 !important;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  border-bottom: 1px dashed #e6e7ea !important;
  color: #21252b !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

:where(.css-dev-only-do-not-override-ixblex) a:hover {
  color: #f9f5ff;
}

:where(.css-dev-only-do-not-override-ixblex) a[disabled] {
  color: #828282;
}

.ant-picker-ok button,
.ant-picker-ok button:hover,
.ant-picker-ok button:focus {
  color: #ffffff !important;
  background: #dca437 !important;
}

:where(.css-dev-only-do-not-override-ixblex).ant-picker-status-error.ant-picker,
:where(
    .css-dev-only-do-not-override-ixblex
  ).ant-picker-status-error.ant-picker:not([disabled]):hover {
  background: #141416 !important;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-picker-outlined {
  background: #f2f2f2 !important;
}

.search-field {
  width: 280px;
  :where(.css-dev-only-do-not-override-djtmh8).ant-form-item {
    margin: 0px;
    width: 280px;
  }
  input {
    background: #ffffff;
    padding: 10px 10px 10px 36px !important;
    border-radius: 8px !important;
    border: 1px solid #efefef;
  }
}

:where(.css-dev-only-do-not-override-1kuana8).ant-dropdown .ant-dropdown-menu,
:where(.css-dev-only-do-not-override-1kuana8).ant-dropdown-menu-submenu
  .ant-dropdown-menu {
  padding: 0px !important;
  border: 1px solid #afb6bd !important;
}

.money-dd {
  padding: 0px !important;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-table-wrapper .ant-table {
  background: none !important;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice {
  padding: 10px 12px !important;
  background: #f5fef7 !important;
  border-radius: 10px;
}

.ant-notification-notice-message {
  margin: 0px !important;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.btn_loading_spinner.ant-spin .ant-spin-dot i {
  background-color: #ffffff;
}
